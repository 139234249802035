import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { ChangeEvent, FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button2, TextButton2 } from 'ui/button/Button';
import { ButtonDropdown } from 'ui/button/ButtonDropdown';
import { Panel } from 'ui/panels';
import cl from './DateRange.module.scss';
import { useCurrentTime } from 'hooks/utils';
import { Input } from 'ui/input/Input';
import { Field } from 'ui/input/Field';
import { ButtonSize } from 'ui/button/types';
import { DateFormat } from 'ui/display';
import { parseDate, toISODateString } from 'utils/date';
import cn from 'classnames';

export interface DateRangeValue {
  dateStart?: string;
  dateEnd?: string;
}

interface Props {
  value?: DateRangeValue | null;
  onChange: (dateRage: DateRangeValue) => void;
  buttonSize?: ButtonSize;
  className?: string;
}

const W = { weekStartsOn: 1 } as const;
export const DateRange: FC<Props> = ({ value, onChange, buttonSize = 'small', className }) => {
  const { t } = useTranslation('ui');
  const [range, setRange] = useState<DateRangeValue | undefined | null>(value);
  const today = useCurrentTime();
  {
    const { dateStart, dateEnd } = value ?? {};
    useEffect(() => {
      setRange((prev) =>
        prev?.dateStart === dateStart && prev?.dateEnd === dateEnd
          ? prev
          : {
              dateStart,
              dateEnd,
            },
      );
    }, [dateStart, dateEnd]);
  }

  const ranges = useMemo<ReadonlyArray<{ label: string; value: DateRangeValue }>>(
    () => [
      {
        label: t('ui:date.today'),
        value: { dateStart: toISODateString(today), dateEnd: toISODateString(today) },
      },
      {
        label: t('ui:date.yesterday'),
        value: {
          dateStart: toISODateString(addDays(today, -1)),
          dateEnd: toISODateString(addDays(today, -1)),
        },
      },
      {
        label: t('ui:date.tomorrow'),
        value: {
          dateStart: toISODateString(addDays(today, 1)),
          dateEnd: toISODateString(addDays(today, 1)),
        },
      },
      {
        label: t('ui:date.currentWeek'),
        value: {
          dateStart: toISODateString(startOfWeek(today, W)),
          dateEnd: toISODateString(endOfWeek(today, W)),
        },
      },
      {
        label: t('ui:date.nextWeek'),
        value: {
          dateStart: toISODateString(startOfWeek(addDays(today, 7), W)),
          dateEnd: toISODateString(endOfWeek(addDays(today, 7), W)),
        },
      },
      {
        label: t('ui:date.cntDays', { N: 14 }),
        value: {
          dateStart: toISODateString(today),
          dateEnd: toISODateString(addDays(today, 13)),
        },
      },
      {
        label: t('ui:date.lastMonth'),
        value: {
          dateStart: toISODateString(startOfMonth(addMonths(today, -1))),
          dateEnd: toISODateString(endOfMonth(addMonths(today, -1))),
        },
      },
      {
        label: t('ui:date.currentMonth'),
        value: {
          dateStart: toISODateString(startOfMonth(today)),
          dateEnd: toISODateString(endOfMonth(today)),
        },
      },
      {
        label: t('ui:date.cntDays', { N: 30 }),
        value: {
          dateStart: toISODateString(today),
          dateEnd: toISODateString(addDays(today, 29)),
        },
      },
      {
        label: t('ui:date.currentQuarter'),
        value: {
          dateStart: toISODateString(startOfQuarter(today)),
          dateEnd: toISODateString(endOfQuarter(today)),
        },
      },
      {
        label: t('ui:date.lastYear'),
        value: {
          dateStart: toISODateString(addYears(startOfYear(today), -1)),
          dateEnd: toISODateString(addYears(endOfYear(today), -1)),
        },
      },
      {
        label: t('ui:date.year'),
        value: {
          dateStart: toISODateString(startOfYear(today)),
          dateEnd: toISODateString(endOfYear(today)),
        },
      },
    ],
    [today, t],
  );

  const handleChangeField = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRange((old) =>
      old ? { ...old, [name]: value } : { dateStart: '', dateEnd: '', [name]: value },
    );
  }, []);

  const handleChangeRange = useCallback(
    (value: DateRangeValue, e: SyntheticEvent) => {
      // REFACT: проверка на это есть в ButtonDropdown для закрытия меню
      e.preventDefault();
      setRange(value);
      onChange(value);
    },
    [onChange],
  );

  const handleOk = useCallback(
    (e: SyntheticEvent) => {
      // REFACT: проверка на это есть в ButtonDropdown для закрытия меню
      e.preventDefault();
      if (range) onChange(range);
    },
    [range, onChange],
  );

  const handleCancel = useCallback((e: SyntheticEvent) => {
    // REFACT: проверка на это есть в ButtonDropdown для закрытия меню
    e.preventDefault();
  }, []);

  return (
    <ButtonDropdown
      buttonSize={buttonSize}
      buttonIcon={null}
      popupPlacement="top-start"
      popupClassName={cl.container}
      popupRaw
      buttonClassName={className}
      itemsHeader={
        <Panel className={cl.panel}>
          <div className={cl.col}>
            {ranges.map((c) => (
              <TextButton2
                key={c.label}
                size="small"
                className={cl.btnRange}
                onClick={handleChangeRange.bind(null, c.value)}
              >
                {c.label}
              </TextButton2>
            ))}
          </div>
          <div className={cn(cl.col, cl.right)}>
            <div>
              <Field label={t('ui:date.dateStart')}>
                <Input
                  type="date"
                  name="dateStart"
                  inputSize="small"
                  value={range?.dateStart}
                  onChange={handleChangeField}
                />
              </Field>
              <Field label={t('ui:date.dateEnd')}>
                <Input
                  type="date"
                  name="dateEnd"
                  inputSize="small"
                  value={range?.dateEnd}
                  onChange={handleChangeField}
                />
              </Field>
            </div>
            <div className={cl.btns}>
              <Button2
                size="small"
                uiColor="secondary"
                className={cl.btnCancel}
                onClick={handleCancel}
              >
                {t('ui:cancel')}
              </Button2>
              <Button2 size="small" onClick={handleOk}>
                {t('ui:ok')}
              </Button2>
            </div>
          </div>
        </Panel>
      }
    >
      {!value || (!value.dateStart && !value.dateEnd) ? (
        t('ui:date.selectDateRange')
      ) : (
        <>
          <DateFormat date={parseDate(value.dateStart)} /> &mdash;{' '}
          <DateFormat date={parseDate(value.dateEnd)} />
        </>
      )}
    </ButtonDropdown>
  );
};
