import { combine, createEffect, createEvent, createStore, merge, restore, sample } from 'effector';
import { createGate, useGate, useStore, useStoreMap } from 'effector-react';
import i18next from 'i18next';
import { and, debounce, not } from 'patronum';
import * as RoMap from '@cubux/readonly-map';
import * as RoSet from '@cubux/readonly-set';
import { apiInstanceList } from 'api/request/wagreen/admin';
import {
  apiChatLatestMessages,
  apiChatList,
  apiChatMarkSeenAll,
  apiChatUsersGet,
  apiChatUsersUpdate,
  WaGreenGroupLatestMessagesParams,
} from 'api/request/wagreen/user';
import { EMPTY_ARRAY, EMPTY_MAP, EMPTY_SET } from 'constants/utils';
import { promptFn, showToastError } from 'ui/feedback';
import { history } from 'utils/history';
import { sortDecorate, strCmp } from 'utils/fn';
import { DateTimeISO, UnixTimestampMilli } from 'utils/types';
import { updateCustomersChannelLastNotice } from '../channel';
import { $currentCompanyId } from '../company';
import { CustomerId } from '../customer/types';
import { $userId } from '../user';
import { UserId } from '../user/types';
import { defaultReducer } from '../utils/defaultReducer';
import { withBookkeeperFx } from '../utils/withBookkeeperFx';
import {
  receivedWaGreenChatSeen,
  receiverActiveChatMessage,
  receiverInactiveChatMessage,
  WaGreenChatGate,
} from './common';
import { receivedWaGreenInstanceState } from './setup';
import { WaGreenGroupForUser, WaGreenGroupId, WaGreenMessage, WithWaGreenGroupId } from './types';

export const WaGreenUserInstancesGate = createGate();
export const WaGreenUserGate = createGate();
export const WaGreenUserChatGate = createGate<{ chatId?: string }>({});
const reloadChats = createEvent<any>();

export const receiveWaGreenUserChatsUpdate = createEvent<any>();
sample({
  source: debounce({ source: receiveWaGreenUserChatsUpdate, timeout: 300 }),
  target: reloadChats,
});

const fetchChatsFx = withBookkeeperFx(createEffect(apiChatList));
export const seenAllFx = withBookkeeperFx(createEffect(apiChatMarkSeenAll));

const $bookkeeperSelected = $currentCompanyId.map(Boolean);
sample({
  clock: [
    sample({
      clock: $currentCompanyId.updates.filter({ fn: Boolean }),
      filter: WaGreenUserGate.status,
    }),
    sample({
      clock: [reloadChats, $userId.updates.filter({ fn: Boolean })],
      filter: and($bookkeeperSelected, WaGreenUserGate.status),
    }),
    sample({ clock: WaGreenUserGate.open, filter: $bookkeeperSelected }),
  ],
  filter: not(fetchChatsFx.pending),
  fn: () => ({}),
  target: fetchChatsFx,
});

export const $chatsLoading = fetchChatsFx.pending;
const $chats = createStore<readonly WaGreenGroupForUser[]>(EMPTY_ARRAY)
  .reset($currentCompanyId, $userId)
  .on(fetchChatsFx.doneData, defaultReducer)
  .on(seenAllFx.doneData, (g, { data }) => {
    if (!data.length) return g;
    const upd = new Map(data.map((s) => [s.id, s.last_seen_message_time]));
    return g.map((g) => {
      const at = upd.get(g.id);
      if (at === undefined) return g;
      return { ...g, last_seen_message_time: at };
    });
  });

// ------------------------------------

const $chatsIds = $chats.map<ReadonlySet<WaGreenGroupId>>((list, prev = EMPTY_SET) =>
  RoSet.syncFrom(prev, new Set(list.map((c) => c.id))),
);

const fetchLatestMessagesFx = withBookkeeperFx(createEffect(apiChatLatestMessages));

export const $latestMessages = createStore<ReadonlyMap<WaGreenGroupId, WaGreenMessage>>(EMPTY_MAP)
  .on($chatsIds, (map, chats) => RoMap.filter(map, (_, id) => chats.has(id)))
  .on(fetchLatestMessagesFx.doneData, (map, { data }) =>
    data.reduce(
      (map, m) =>
        RoMap.updateDefault(map, m.group_id, undefined, (p) =>
          p && p.created_at > m.created_at ? p : m,
        ),
      map,
    ),
  );

export const $chatsSortedByLatestMessage = combine(
  $chats,
  $latestMessages.map((map) => RoMap.map(map, (m) => m.created_at)),
  (chats, latest) =>
    sortDecorate(
      (c: WaGreenGroupForUser) => [c, latest.get(c.id)] as const,
      ([c]) => c,
    )(([ac, aT = ''], [bc, bT = '']) => strCmp(bT, aT) || strCmp(ac.name, bc.name))(chats),
);

const $latestMsg = $latestMessages.map((map) =>
  RoMap.reduce(
    map,
    (l: WaGreenMessage | null, m) => (l && l.created_at > m.created_at ? l : m),
    null,
  ),
);
const $latestMsgId = $latestMsg.map((m) => (m ? m.id : null));
const $latestExistingTime = $latestMessages.map((map) => RoMap.map(map, (m) => m.created_at));

sample({
  clock: [$chatsIds, receiverInactiveChatMessage, receiverActiveChatMessage],
  source: {
    after_id: $latestMsgId,
    _chats: $chatsIds,
  },
  filter: ({ _chats }) => _chats.size > 0,
  fn: ({ after_id }): WaGreenGroupLatestMessagesParams => ({
    after_id: after_id ?? undefined,
  }),
  target: fetchLatestMessagesFx,
});

// ------------------------------------

export const $hasChats = $chats.map((list) => list.length > 0);
export const $hasChatsForCustomers = $chats.map<ReadonlySet<CustomerId>>((list) =>
  list.reduce(
    (set, ch) => ch.for_customers?.reduce((set, r) => set.add(r), set) ?? set,
    new Set<CustomerId>(),
  ),
);
export const $chatsById = $chats.map((list) => RoMap.fromArray(list, (g) => g.id));
export const $chatsByCustomers = $chats.map<
  ReadonlyMap<CustomerId, readonly WaGreenGroupForUser[]>
>((list) => {
  const m = new Map<CustomerId, WaGreenGroupForUser[]>();
  for (const g of list) {
    g.for_customers?.forEach((cId) => {
      const to = m.get(cId);
      if (to) {
        to.push(g);
      } else {
        m.set(cId, [g]);
      }
    });
  }
  return m;
});

const $selectedChat = combine($chats, WaGreenUserChatGate.state, (list, { chatId }) => {
  const id = Number(chatId);
  return (Number.isInteger(id) && id > 0 && list.find((c) => c.id === id)) || null;
});
export const $selectedChatId = $selectedChat.map((c) => (c ? c.id : null));

const $chatSeenTime = createStore<ReadonlyMap<WaGreenGroupId, DateTimeISO>>(EMPTY_MAP)
  .reset($currentCompanyId)
  .on($chats, (map, existing) => {
    const have = new Map(
      existing
        .map((c) => [c.id, c.last_seen_message_time])
        .filter((v): v is [WaGreenGroupId, DateTimeISO] => Boolean(v[1])),
    );
    // убираем те, что ушли
    const next = RoMap.filter(map, (_, id) => have.has(id));
    // обновляем максимальное время, где оно стало больше
    return RoMap.reduce(
      have,
      (next, haveT, id) =>
        RoMap.updateDefault(next, id, undefined, (p) =>
          p && new Date(p).getTime() >= new Date(haveT).getTime() ? p : haveT,
        ),
      next,
    );
  })
  .on(receivedWaGreenChatSeen, (map, { chatId, seen }) =>
    seen ? RoMap.set(map, chatId, seen) : RoMap.remove(map, chatId),
  )
  .on(receiverInactiveChatMessage, (map, { chatId, silent, at }) => {
    // #254 опция "не уведомлять об исходящих"
    if (silent && at) {
      // т.е. если мы *отвечаем*, значит прежние входящие уже увидели, поэтому
      // они вполне легально становятся прочитанными
      return RoMap.updateDefault(map, chatId, null, (p) => (p && p > at ? p : at));
    }
    return map;
  });

export const useChatLastSeenTime = (id: WaGreenGroupId) =>
  useStoreMap({
    store: $chatSeenTime,
    keys: [id],
    fn: (m, [id]) => m.get(id) ?? null,
  });

export const $hasNewMessages = combine(
  $latestExistingTime,
  $chatSeenTime,
  (existing, seen): ReadonlySet<WaGreenGroupId> =>
    RoMap.reduce(
      existing,
      (news, latest, id) => (seen.has(id) && seen.get(id)! >= latest ? news : news.add(id)),
      new Set<WaGreenGroupId>(),
    ),
);

export const useInfo = () => {
  useGate(WaGreenUserGate);
  return {
    hasNewMessages: useStore($newMessagesAnywhere),
    hasChats: useStore($hasChats),
  };
};

// export const $newMessagesAnywhere = (
//   $newMessagesCount.map((map) => RoMap.some(map, (count) => count > 0))
// );
export const $newMessagesAnywhere = $hasNewMessages.map((set) => set.size > 0);
export const $newMessagesForCustomer = combine($hasNewMessages, $chats, (where, chats) => {
  const set = new Set<CustomerId>();
  for (const { id, for_customers } of chats) {
    if (for_customers && where.has(id)) {
      for (const c of for_customers) {
        set.add(c);
      }
    }
  }
  return set;
});

// ------------------------------------

const _instanceStateChanged = sample({
  source: receivedWaGreenInstanceState,
  filter: WaGreenUserInstancesGate.status,
});

const _fetchInstancesFx = withBookkeeperFx(createEffect(apiInstanceList));
export const $instances = restore(
  _fetchInstancesFx.doneData.map(({ data }) => data),
  EMPTY_ARRAY,
)
  .reset(WaGreenUserInstancesGate.close)
  .on(_instanceStateChanged, (list, { instanceId, ...upd }) =>
    list.map((a) => (a.id === instanceId ? { ...a, ...upd } : a)),
  );
export const $instancesMap = $instances.map((list) => RoMap.fromArray(list, (a) => a.id));
sample({
  source: WaGreenUserInstancesGate.open,
  fn: () => {},
  target: _fetchInstancesFx,
});

// ------------------------------------

export const fetchWaGreenChatUsers = createEvent<WithWaGreenGroupId>();
const fetchChatUsersFx = withBookkeeperFx(createEffect(apiChatUsersGet));
const updateChatUsersFx = withBookkeeperFx(createEffect(apiChatUsersUpdate));
sample({
  clock: [
    WaGreenChatGate.open.map((p) => ({ group_id: p.group_id })),
    sample({
      clock: fetchWaGreenChatUsers,
      source: WaGreenChatGate.state,
      filter: (g, e) => Boolean(g && g.group_id === e.group_id),
      fn: (_, e) => e,
    }),
  ],
  target: fetchChatUsersFx,
});
sample({ source: updateChatUsersFx.done, target: reloadChats });

export const $chatUsersList = restore(
  merge([fetchChatUsersFx.doneData, updateChatUsersFx.doneData]).map<ReadonlySet<UserId>>(
    ({ data }) => new Set(data.user_id),
  ),
  EMPTY_SET,
).reset(WaGreenChatGate.close);

export const saveChatUsersFx = createEffect(
  async (p: WithWaGreenGroupId & { usersId: readonly UserId[] }) => {
    if (p.group_id === WaGreenChatGate.state.getState()?.group_id) {
      const prev = $chatUsersList.getState();
      const next = new Set(p.usersId);
      const add = RoSet.subtract(next, prev);
      const remove = RoSet.subtract(prev, next);
      if (add.size || remove.size) {
        await updateChatUsersFx({
          group_id: p.group_id,
          add_user_id: Array.from(add),
          remove_user_id: Array.from(remove),
        });
      }
    }
  },
);
// export const $saveChatUsersPending = saveChatUsersFx.pending;
export const leaveChatFx = createEffect(async (p: WithWaGreenGroupId) => {
  if (p.group_id === WaGreenChatGate.state.getState()?.group_id) {
    if (await promptFn(i18next.t('whatsapp:chat.LeaveChatConfirm'))) {
      await updateChatUsersFx({
        group_id: p.group_id,
        remove_user_id: [$userId.getState()!],
      });
      return true;
    }
  }
});
leaveChatFx.done.watch(({ params: { group_id }, result }) => {
  if (result && window.location.pathname === `/whatsappg/${group_id}`) {
    history.push('/whatsappg');
  }
});
export const $leaveChatPending = updateChatUsersFx.pending;

merge([fetchChatsFx.failData, updateChatUsersFx.failData, seenAllFx.failData]).watch(
  showToastError,
);

// ------------------------------------

// export const closeWaitingRestartInstance = createEvent<any>();
// export const restartInstance = createEvent<WaGreenInstanceId>();
//
// const _restartChatInstanceFx = withBookkeeperFx(
//   createEffect(async (params: WithBookkeeper & WithWaGreenInstanceId) => {
//     if (
//       await promptFn(
//         <Trans i18nKey="account:integrations.whatsapp.message.restartByUser.Confirm" />,
//         { okText: i18next.t('account:integrations.whatsapp.message.restartByUser.OK') },
//       )
//     ) {
//       try {
//         await apiInstanceRestart(params);
//         return true;
//       } catch (e) {
//         const at = isConflictRetryAfter(e);
//         if (!at) {
//           throw e;
//         }
//         showToast(
//           ToastTypes.error,
//           <>
//             {i18next.t('account:integrations.whatsapp.message.ErrorRestartAfter')}{' '}
//             <DateFormat date={at} format="Ppp" />
//           </>,
//         );
//       }
//     }
//     return false;
//   }),
// );
// sample({
//   source: restartInstance,
//   fn: (instance_id) => ({ instance_id }),
//   target: _restartChatInstanceFx,
// });
// sample({
//   source: _restartChatInstanceFx.doneData,
//   filter: Boolean,
// }).watch(() =>
//   showToast(
//     ToastTypes.loading,
//     i18next.t('account:integrations.whatsapp.message.RestartRequestedSuccess'),
//   ),
// );
// _restartChatInstanceFx.failData.watch(showToastError);

//------------------
//Обновим даты последних сообщений в каналах для правильной сортировки
sample({
  clock: fetchLatestMessagesFx.doneData,
  source: $chats,
  fn: (chats, { data }) => {
    const update = new Map<CustomerId, UnixTimestampMilli>();
    data.forEach((message) => {
      const last_notice_at = new Date(message.created_at).getTime() as UnixTimestampMilli;
      chats
        .find((chat) => chat.id === message.group_id)
        ?.for_customers?.forEach((customerId) => {
          update.set(customerId, last_notice_at);
        });
    });
    return update;
  },
  target: updateCustomersChannelLastNotice,
});

// const _resetRestartWaiting = merge([closeWaitingRestartInstance, WaGreenUserInstancesGate.close]);
// const _$waitRestartInstanceId = restore(
//   sample({
//     source: _restartChatInstanceFx.done,
//     filter: ({ result }) => result,
//     fn: ({ params: { instance_id } }) => instance_id,
//   }),
//   null,
// ).reset(_resetRestartWaiting);
// const _$waitRestart = _$waitRestartInstanceId.map(Boolean);
//
// export const $waitRestartInstance = combine(
//   _$waitRestartInstanceId,
//   $instances,
//   (id, list) => (id && list.find((a) => a.id === id)) || null,
// );
//
// export enum RestartPhase {
//   OFF,
//   ACCEPTED,
//   HANGED,
//   COMPLETED,
// }
// const _restartAccepted = sample({
//   source: _$waitRestartInstanceId,
//   filter: Boolean,
// });
// const _restartComplete = sample({
//   clock: $waitRestartInstance.map((a) => a?.state ?? null),
//   source: _$waitRestart,
//   filter: (ok, state) => ok && state !== null && state !== WaGreenInstanceState.starting,
// });
// const _restartHang = delayEvent({
//   source: _restartAccepted,
//   timeout: 60_000,
//   cancel: merge([_resetRestartWaiting, _restartComplete]),
//   cancelOnRestart: true,
// });
// export const $restartPhase = createStore(RestartPhase.OFF)
//   .reset(_resetRestartWaiting)
//   .on(_restartAccepted, () => RestartPhase.ACCEPTED)
//   .on(_restartHang, () => RestartPhase.HANGED)
//   .on(_restartComplete, () => RestartPhase.COMPLETED);
