import { createEffect, createEvent, createStore, restore, sample } from 'effector';
import { createGate } from 'effector-react';
import { or } from 'patronum';
import * as RoMap from '@cubux/readonly-map';
import {
  apiFilesDelete,
  apiFilesStatV2,
  apiFilesUpdateBatchVoid,
  apiFilesUpload,
} from 'api/request/filestorage';
import { EMPTY_MAP } from 'constants/utils';
import { isNotNull } from 'utils/fn';
import { randomKey } from 'utils/helpers';
import { IdOf } from 'utils/types';
import { bottleneckFx } from '../../utils/bottleneckFx';
import { createRequestEffectWithCancel } from '../../utils/createRquestEffect';
import { withBookkeeperFx } from '../../utils/withBookkeeperFx';
import { FileId, FileStorage } from '../types';
import { filesharePropertiesMatch, fileshareToFileStorage } from './fn';
import {
  Fileshare,
  FileshareStat,
  FilesSetProperties,
  FileStorageServiceString,
  FilesUploadFormV2,
} from './types';

// const downloadFileFx = createEffect(apiFilesDownload);
const updateBatchVoidFx = withBookkeeperFx(createEffect(apiFilesUpdateBatchVoid));
const { deleteFileFx } = bottleneckFx({ deleteFileFx: apiFilesDelete }, { limit: 5 });

// ----------------------------------------------

type GateProps = FilesSetProperties;

interface GateState extends GateProps {
  committed?: boolean;
}

// ----------------------------------------------

export type FileRecordKey = IdOf<'FileRecord'> | string;

const enum Kind {
  LOCAL = 'L',
  REMOTE = 'R',
}

export { Kind as FileRecordKind };

interface FileRecordBase {
  key: FileRecordKey;
  kind: Kind;
  /**
   * Зачем это надо:
   *
   * 1.  Открыли редактирование задачи или чего бы там ни было.
   * 2.  Убрали пару файлов из списка.
   * 3.  Добавили новые файлы.
   * 4.  Завершение редактирования:
   *     - Если "сохранить" (commit), то файлы из п.2 можно удалить.
   *     - Если "отмена", то файлы из п.3 можно удалить.
   */
  DELETE?: boolean;
  /**
   * У локального здесь только основные поля, которые можно взять из File.
   * У загруженного здесь весь Fileshare.
   * Не надо подделывать id в локальном файле.
   */
  fileshare: FileshareStat;
  /**
   * У новых обязателен. У загруженных остаётся нетронутым от локального.
   */
  file?: File;
  /**
   * У удалённых true, если этот файл был
   */
  isPrevious?: boolean;
  // по upload или stat соответственно kind
  pending?: boolean;
  // по upload или stat соответственно kind
  error?: Error;
}

export interface FileRecordLocal extends FileRecordBase {
  kind: Kind.LOCAL;
  fileshare: FileshareStat;
  file: File;
}

export interface FileRecordRemote extends FileRecordBase {
  kind: Kind.REMOTE;
  fileshare: Fileshare;
}

export type FileRecord = FileRecordLocal | FileRecordRemote;
type MapFiles = ReadonlyMap<FileRecordKey, FileRecord>;
export type { MapFiles as UploadingListFilesMap };

const newRecordLocal = (file: File): FileRecordLocal => ({
  key: randomKey(16),
  kind: Kind.LOCAL,
  fileshare: {
    file_type: file.type,
    size: file.size,
    original_name: file.name,
    // extension: getFileExtension(file.name, true),
  },
  file,
});
const newRecordRemote = (fs: Fileshare): FileRecordRemote => ({
  key: randomKey(16),
  kind: Kind.REMOTE,
  fileshare: fs,
  isPrevious: true,
});
export { newRecordLocal as newFileRecordLocal, newRecordRemote as newFileRecordRemote };
export const isFileRecordRemote = (fr: FileRecord): fr is FileRecordRemote =>
  fr.kind === Kind.REMOTE;

const getRemotesMap = (m: MapFiles) => {
  const existing = new Map<FileId, FileRecordRemote>();
  for (const fr of m.values()) {
    if (fr.kind === Kind.REMOTE) {
      existing.set(fr.fileshare.id, fr);
    }
  }
  return existing;
};

// ----------------------------------------------

interface Options {
  /**
   * Разрешает удалять старые файлы после commit, которые удалили
   */
  allowDeleteFreeFile?: boolean;
  /**
   * `service` по умолчанию, когда в `Gate` не установлен иной (`!== undefined`)
   */
  service?: FileStorageServiceString;
  /**
   * При стандартном использовании <input> мы не можем добавить файл, удалить его, а потом снова добавить.
   * А если мы применим фичу с перемонтированием, то сможем добавлять один файл много раз. Необходимо сделать проверку на уникальность.
   * Пока сделал только name+size+lastModified, в теории может быть не достаточно.
   *
   * https://trello.com/c/sSmnBicY/1435
   * Ладно. Но тогда изначально получается ерунда: выбрал файл, удалил его,
   * выбрал его же ещё раз - а он не добавляется. Так не должно быть. Поэтому
   * перемонтирование <input> должно быть в любом случае.
   */
  checkUnique?: boolean;

  DEBUG?: string;
}

// TODO: возможно, было бы лучше `commit` сделать эффектом `saveFx`, который
//  сохранял бы изменения именно в тот момент, когда вызван, чтобы можно было
//  ещё и результата дождаться. Поживём - увидим.

export interface UploadingListAPI extends ReturnType<typeof createUploadingList> {}

/**
 * Создаёт новый стор и всё API к нему
 *
 * Вместо прежнего `listId: FileStorageListId` с единым общим стором сделал
 * такую штуку, чтобы здесь всё было внутри изолировано.
 *
 * В отличие от старой версии здесь важно правильно использовать событие
 * `commit` и не путать предустановку `set*` с пользовательским добавлением
 * `add*` (см. соотв. описания).
 *
 * ```tsx
 * const filesApi = createUploadingList({
 *   service: FileStorageService._,
 * });
 *
 * // в каком-нибудь главном компоненте
 * useGate(filesApi.Gate, {
 *   //customers: ...,
 * });
 *
 * // предустановка предыдущих файлов (при открытом гейте)
 * filesApi.setFilesBy_(...);
 *
 * // если нужен список файлов
 * const files = useStore(filesApi.$files);
 * const filesId = useStore(filesApi.$filesId);
 *
 * // сохранение изменений
 * // важно вызвать commit() до закрытия гейта, но после успеха с основным
 * // объектом, которому принадлежат файлы
 * filesApi.commit();
 *
 * // рендер списка
 * return <FilesListV2 api={filesApi} ... />;
 * // или
 * const MyFilesList = bindFilesListV2(filesApi);
 * return <MyFilesList ... />
 * ```
 *
 * @see bindFilesListV2
 *
 * @todo Сразу не догадался: можно было сделать ещё опцию `readOnly`, т.к. есть
 *   места применения, где список используется только для отображения файлов без
 *   загрузки и удаления. Возвращаемый набор полей тогда тоже поменьше будет.
 *   Или даже лучше вынести режим readonly в отдельную функцию-фабрику, чтобы ЭТА
 *   работала с результатом ТОЙ фабрики.
 */
export const createUploadingList = ({
  allowDeleteFreeFile = false,
  checkUnique = false,
  service,
  DEBUG,
}: Options = {}) => {
  const { request: uploadRawFx, cancel: uploadCancel } = createRequestEffectWithCancel(
    apiFilesUpload,
    false,
  );
  const uploadFx = withBookkeeperFx(bottleneckFx({ uFx: uploadRawFx }).uFx);
  const statByIdsFx = withBookkeeperFx(createEffect(apiFilesStatV2));

  const Gate = createGate<GateProps>();
  const commit = createEvent<unknown>();
  const openGate = createEvent<GateProps>();
  sample({ clock: Gate.open, target: openGate });
  const updateGate = sample({ clock: Gate.state, filter: Gate.status });
  interface WillClose {
    gate: GateState;
    files: MapFiles;
  }
  const willCloseGate = createEvent<WillClose>();
  const didCloseGate = createEvent<WillClose>();
  const reopen = createEvent<unknown>();

  const DEF_GATE_PROPS: GateState = {};
  const $gateState = restore<GateState>(updateGate, DEF_GATE_PROPS)
    .reset(didCloseGate)
    // committed сбрасывается при изменении состояния гейта updateGate
    .on(sample({ clock: commit, filter: Gate.status }), (s) =>
      s.committed ? s : { ...s, committed: true },
    )
    .on(sample({ clock: openGate, filter: Gate.status }), (s) =>
      s.committed ? { ...s, committed: false } : s,
    );

  const $mergeGateState = service
    ? $gateState.map<GateState>(({ service: s = service, ...rest }) => ({ ...rest, service }))
    : $gateState;

  // ------------------

  const setFilesList = createEvent<readonly FileRecord[]>();
  const setFilesById = createEvent<readonly FileId[]>();
  const setFileshareInternal = createEvent<readonly FileRecordRemote[]>();
  const addFilesharesInternal = createEvent<readonly FileRecordRemote[]>();
  const addFilesLocal = createEvent<readonly FileRecordLocal[]>();
  const addFiles = (files: readonly File[]) => {
    const fss: readonly FileRecordLocal[] = files.map(newRecordLocal);
    addFilesLocal(fss);
    return fss;
  };
  const removeFile = createEvent<FileRecordKey>();
  const removeFileId = createEvent<FileId>();
  const removeAll = createEvent<unknown>();
  const replaceFileshares = createEvent<readonly Fileshare[]>();

  const $files = createStore<MapFiles>(EMPTY_MAP)
    .reset(didCloseGate)
    .on(setFilesList, (m, frs) => {
      // чтобы у прежних было без изменений, особенно isPrevious
      const nextMap = new Map(
        frs.map((fr) => {
          const prevFr = m.get(fr.key);
          const nextFr = prevFr ? (prevFr.DELETE ? { ...prevFr, DELETE: false } : prevFr) : fr;
          return [fr.key, nextFr];
        }),
      );
      // добавить остальные `DELETE:true`
      for (const fr of m.values()) {
        if (fr.DELETE && !nextMap.has(fr.key)) {
          nextMap.set(fr.key, fr);
        }
      }
      return nextMap;
    })
    .on(setFileshareInternal, (m, frs) => {
      const nextMap = new Map<FileRecordKey, FileRecord>(frs.map((fr) => [fr.key, fr]));
      // добавить остальные `DELETE:true`
      for (const fr of m.values()) {
        if (fr.DELETE && !nextMap.has(fr.key)) {
          nextMap.set(fr.key, fr);
        }
      }
      return nextMap;
    })
    .on(addFilesLocal, (m, frs) =>
      frs.reduce((m, fr) => {
        if (
          checkUnique &&
          [...m.values()].some(
            ({ file, DELETE }) =>
              !DELETE &&
              file &&
              file.name === fr.file.name &&
              file.size === fr.file.size &&
              file.lastModified === fr.file.lastModified,
          )
        ) {
          return m;
        }
        return RoMap.set(m, fr.key, fr);
      }, m),
    )
    .on(addFilesharesInternal, (m, frs) => frs.reduce((m, fr) => RoMap.set(m, fr.key, fr), m))
    .on(uploadFx, (m, { local_id }) => {
      if (!local_id) return m;
      return local_id.reduce(
        // update, не updateDefault, чтобы не создавать новые записи из воздуха
        (m, key) => RoMap.update(m, key, (prev) => ({ ...prev, pending: true })),
        m,
      );
    })
    .on(statByIdsFx, (m, { f }) => {
      const ids = new Set(f);
      return RoMap.map(m, (fr) =>
        fr.kind === Kind.REMOTE && ids.has(fr.fileshare.id) ? { ...fr, pending: true } : fr,
      );
    })
    .on(uploadFx.doneData, (m, { data: list }) => {
      const uploaded = RoMap.fromArray(list, (fs) => fs.local_id);
      return RoMap.map(m, (fr, key) => {
        const fs = uploaded.get(key);
        if (!fs) return fr;
        const next: FileRecordRemote = {
          ...fr,
          kind: Kind.REMOTE,
          error: undefined,
          fileshare: fs,
          pending: false,
        };
        return next;
      });
    })
    .on(statByIdsFx.doneData, (m, { data: list }) => {
      const stats = RoMap.fromArray(list, (fs) => fs.id);
      return RoMap.map(m, (fr) => {
        if (fr.kind !== Kind.REMOTE) return fr;
        const fs = stats.get(fr.fileshare.id);
        if (!fs) return fr;
        const next: FileRecordRemote = {
          ...fr,
          kind: Kind.REMOTE,
          isPrevious: true,
          error: undefined,
          fileshare: fs,
          pending: false,
        };
        return next;
      });
    })
    .on(uploadFx.fail, (m, { params: { local_id }, error }) => {
      if (!local_id) return m;
      return local_id.reduce(
        // update, не updateDefault, чтобы не создавать новые записи из воздуха
        (m, key) => RoMap.update(m, key, (fr) => ({ ...fr, pending: false, error })),
        m,
      );
    })
    .on(statByIdsFx.fail, (m, { params: { f }, error }) => {
      const ids = new Set(f);
      return RoMap.map(m, (fr) =>
        fr.kind === Kind.REMOTE && ids.has(fr.fileshare.id) ? { ...fr, pending: false, error } : fr,
      );
    })
    .on(removeFile, (m, key) =>
      RoMap.update(m, key, (fr) => (fr.DELETE ? fr : { ...fr, DELETE: true })),
    )
    .on(removeFileId, (m, id) => {
      const key = RoMap.findKey(m, (fr) => fr.kind === Kind.REMOTE && fr.fileshare.id === id);
      if (!key) return m;
      return RoMap.update(m, key, (fr) => (fr.DELETE ? fr : { ...fr, DELETE: true }));
    })
    .on(removeAll, (m) => RoMap.map(m, (fr) => (fr.DELETE ? fr : { ...fr, DELETE: true })));

  const setByFileshareFx = createEffect((fss: readonly Fileshare[]) => {
    // чтобы у прежних было без изменений, особенно isPrevious
    const files = $files.getState();
    const existing = getRemotesMap(files);
    const frs: readonly FileRecordRemote[] = fss.map((fs) => {
      const prevFr = existing.get(fs.id);
      if (!prevFr) {
        return newRecordRemote(fs);
      }
      if (prevFr.DELETE || prevFr.fileshare !== fs) {
        return { ...prevFr, DELETE: false, fileshare: fs };
      }
      return prevFr;
    });
    setFileshareInternal(frs);
    return frs;
  });

  const addFilesharesFx = createEffect((fss: readonly Fileshare[]) => {
    const files = $files.getState();
    const existing = getRemotesMap(files);
    const frs: readonly FileRecordRemote[] = fss.map((fs) => {
      const prevFr = existing.get(fs.id);
      if (!prevFr) {
        return newRecordRemote(fs);
      }
      return { ...prevFr, DELETE: false, fileshare: fs };
    });
    addFilesharesInternal(frs);
    return frs;
  });

  sample({
    clock: Gate.close,
    source: { gate: $mergeGateState, files: $files },
    filter: ({ gate }) => gate !== DEF_GATE_PROPS,
    target: [willCloseGate, didCloseGate],
  });
  const reopenIntermediate = createEvent<WillClose>();
  sample({
    clock: reopen,
    source: { gate: $mergeGateState, files: $files },
    filter: Gate.status,
    target: [willCloseGate, didCloseGate, reopenIntermediate],
  });
  reopenIntermediate.watch(({ gate: { committed, ...s } }) => openGate(s));

  willCloseGate.watch(({ gate: { committed = false, ...filesProps }, files }) => {
    const toDelete: FileId[] = [];
    if (committed) {
      // сохранение

      const toUpdate: FileId[] = [];
      for (const fr of files.values()) {
        if (fr.kind === Kind.REMOTE) {
          const fs = fr.fileshare;
          if (fr.DELETE) {
            // можем удалять:
            // - новые залитые: всегда
            // - старые освободившиеся: если разрешено
            if (!fr.isPrevious || allowDeleteFreeFile) {
              toDelete.push(fs.id);
            }
          } else if (!filesharePropertiesMatch(fs, filesProps)) {
            toUpdate.push(fs.id);
          }
        }
      }
      if (toUpdate.length) {
        updateBatchVoidFx({
          files_id: toUpdate,
          // TODO: как-то накапливать и отправлять только то, что действительно
          //   было изменено в открытом гейте
          service: filesProps.service ?? '',
          customers: filesProps.customers ?? [],
        });
      }
    } else {
      // отмена

      uploadCancel();
      for (const fr of files.values()) {
        if (fr.kind === Kind.REMOTE) {
          const fs = fr.fileshare;
          // можем удалять только новые файлы, которые успели залить
          // локальное удаление DELETE ничего не значит, ибо отмена
          if (!fr.isPrevious) {
            toDelete.push(fs.id);
          }
        }
      }
    }

    toDelete.forEach((id) => deleteFileFx(id));
  });

  sample({
    clock: sample({ clock: addFilesLocal, filter: Gate.status }),
    source: $mergeGateState,
    fn: ({ committed, ...g }, frs): FilesUploadFormV2 => ({
      files: frs.map((fr) => fr.file),
      local_id: frs.map((fr) => fr.key),
      ...g,
    }),
    target: uploadFx,
  });
  sample({
    clock: sample({ clock: setFilesById, filter: Gate.status }),
    source: $files,
    fn: (m, ids) => {
      const existing = getRemotesMap(m);
      const known: FileRecord[] = [];
      const toStat = new Set<FileId>();
      for (const id of ids) {
        const fr = existing.get(id);
        if (fr) {
          known.push(fr);
        } else {
          toStat.add(id);
        }
      }
      return { known, toStat };
    },
  }).watch(({ known, toStat }) => {
    setFilesList(known);
    statByIdsFx({ f: Array.from(toStat) });
  });

  sample({
    clock: replaceFileshares,
    filter: Gate.status,
  }).watch((fss) => {
    removeAll();
    addFilesharesFx(fss);
  });

  const $filesOk = $files.map((m) => RoMap.filter(m, (fr) => !fr.DELETE));
  const $fileshares = $filesOk.map<readonly Fileshare[]>((frs) =>
    Array.from(frs.values(), (fr) => (fr.kind === Kind.REMOTE ? fr.fileshare : null)).filter(
      isNotNull,
    ),
  );
  const $totalSize = $filesOk.map((frs) =>
    RoMap.reduce(frs, (sum, fr) => sum + fr.fileshare.size, 0),
  );

  if (process.env.NODE_ENV === 'development' && DEBUG) {
    Gate.open.watch((p) => console.log(DEBUG, '>> Gate.open', p));
    Gate.close.watch((p) => console.log(DEBUG, '>> Gate.close', p));
    Gate.state.watch((p) => console.log(DEBUG, '>> Gate.state', p));
    Gate.status.watch((p) => console.log(DEBUG, '>> Gate.status', p));
    commit.watch(() => console.log(DEBUG, '>> commit'));
    openGate.watch((p) => console.log(DEBUG, '>> openGate', p));
    updateGate.watch((p) => console.log(DEBUG, '>> updateGate', p));
    willCloseGate.watch((p) => console.log(DEBUG, '>> willCloseGate', p));
    didCloseGate.watch((p) => console.log(DEBUG, '>> didCloseGate', p));
    $gateState.watch((p) => console.log(DEBUG, '>> $gateState', p));
    $mergeGateState.watch((p) => console.log(DEBUG, '>> $mergeGateState', p));

    $files.watch((p) => console.log(DEBUG, '>> $files', p));
    $filesOk.watch((p) => console.log(DEBUG, '>> $filesOk', p));
  }

  return {
    /**
     * Gate для работы со списком
     *
     * В свойства можно указывать и менять общие поля для группы файлов.
     * Например, в Задаче меняются Клиенты - их можно менять здесь.
     *
     * Новые загружаемые файлы будут сразу иметь такие свойства.
     *
     * Последующие изменения этих свойств отправляются на сервер только при
     * закрытии гейта, если перед закрытием был затриггерент `commit`.
     *
     * - При закрытии по сценарию "сохранение":
     *   1. выполняется обновление изменённых общих свойств, указанных в `Gate`
     *   2. безусловное удаление новых залитых файлов, удалённых до commit
     *   3. если разрешено `Options.allowDeleteFreeFile`, удаляются также
     *      удалённые файлы, существовавшие ранее (`setFilesById`, `setFilesByFileshare`)
     * - При закрытии по сценарию "отмена":
     *   1. отмена незавершенных загрузок файлов
     *   2. безусловное удаление новых залитых файлов
     *
     * **ВАЖНО!** Если возможна смена "цели", которой принадлежат файлы, без
     * непосредственного закрытия и открытия гейта, обязательно надо
     * использовать:
     * - либо React key `<Gate key={subjectId} />`, чтобы вызывать настоящее
     *   закрытие и открытие при смене `subjectId`;
     * - либо триггерить `reopen`, чтобы вызывать фиктивное закрытие и открытие
     * Иначе будет мешанина. Не забыть наладить вызов `commit` перед этим.
     * Пример: написать новое сообщение в чате.
     *
     * @see commit
     * @see reopen
     * @see Options.allowDeleteFreeFile
     */
    Gate,
    /**
     * Важно затриггерить это событие перед закрытием `Gate`, чтобы закрытие
     * работало по сценарию "сохранение". Иначе закрытие будет работать по
     * сценарию "отмена".
     *
     * Будучи вызванным один раз, нельзя отменить его действие без закрытия и
     * нового открытия `Gate`.
     */
    commit,
    /**
     * Фиктивное закрытие и открытие
     *
     * Вызывает те же действия, что и настоящее закрытие и открытие с такими же
     * параметрами.
     *
     * Назвал именно `reopen`, а не `reset`, для более точного определения, что
     * оно делает.
     */
    reopen,
    /**
     * Предустановка прежних файлов по id
     *
     * Данные запрашиваются с сервера.
     *
     * "Прежние" файлы помечены внутренним флагом `isPrevious: true`. Удалённые
     * прежние файлы будут удаляться с сервера при `commit`, если разрешено
     * `Options.allowDeleteFreeFile`
     *
     * @see commit
     * @see Options.allowDeleteFreeFile
     */
    setFilesById,
    /**
     * Предустановка прежних файлов сразу моделями
     *
     * "Прежние" файлы помечены внутренним флагом `isPrevious: true`. Удалённые
     * прежние файлы будут удаляться с сервера при `commit`, если разрешено
     * `Options.allowDeleteFreeFile`
     *
     * @see commit
     * @see Options.allowDeleteFreeFile
     * @see fileshareFromFileStorage
     */
    setFilesByFileshare: setByFileshareFx,
    /**
     * Добавление новых файлов с загрузкой
     *
     * "Новые" файлы имеют внутренний флаг `isPrevious?: false`. Ненужные новые
     * файлы будут удалены после закрытия согласно сценарию закрытия.
     */
    addFiles,
    /**
     * Добавление новых файлов уже готовыми моделями.
     *
     * **ВАЖНО!** Эти файлы также считаются "новыми" для консистенции с `addFiles`,
     * хотя на практике это может быть и неверно. Доработать при необходимости,
     * добавив новое событие ниже.
     *
     * "Новые" файлы имеют внутренний флаг `isPrevious?: false`. Ненужные новые
     * файлы будут удалены после закрытия согласно сценарию закрытия.
     *
     * @see fileshareFromFileStorage
     */
    addFileshares: addFilesharesFx,
    /**
     * Удаление файла по ключу в данном сторе
     *
     * Файл помечается `DELETE: true` для дальнейшей обработки.
     */
    removeFile,
    /**
     * Удаление файла по id файла в хранилище
     *
     * Не имеет смысла, если файл с таким id не прогружен.
     *
     * Файл помечается `DELETE: true` для дальнейшей обработки.
     */
    removeFileId,
    /**
     * Удаление всех файлов
     *
     * Файл помечается `DELETE: true` для дальнейшей обработки.
     */
    removeAll,
    /**
     * Замена файлов сразу моделями
     *
     * Эквивалентно `removeAll(); addFileshares(fss);`
     *
     * **ВАЖНО!** Это изменение пользователем. Не путать с предустановкой `set*`.
     *
     * @see fileshareFromFileStorage
     */
    replaceFileshares,
    /**
     * Есть ли файлы в `$files`
     */
    $hasFiles: $filesOk.map((a) => a.size > 0),
    /**
     * Файлы, без помеченных к удалению
     */
    $files: $filesOk,
    /**
     * ID файлов
     *
     * Следует учитывать `$pending` для ожидания загрузки новых файлов.
     */
    $filesId: $fileshares.map<readonly FileId[]>((fss) => fss.map((fs) => fs.id)),
    /**
     * Загруженные Fileshare
     *
     * Следует учитывать `$pending` для ожидания загрузки новых файлов.
     */
    $fileshares,
    /**
     * Загруженные FileStorage старого типа для
     *
     * Следует учитывать `$pending` для ожидания загрузки новых файлов.
     */
    $filesOld: $fileshares.map<readonly FileStorage[]>((fss) => fss.map(fileshareToFileStorage)),
    $totalSize,
    // $filesAll: $files,
    /** Выполняется загрузка файлов на сервер */
    $pendingUpload: uploadFx.pending,
    /** Выполняется получение данных файлов по `setFilesById` */
    $pendingStat: statByIdsFx.pending,
    /**
     * Выполняется какая-либо не фоновая операция
     *
     * Удаление ненужных файлов и обновление общих параметров выполняется в фоне
     * без какого-то фидбека.
     */
    $pending: or(uploadFx.pending, statByIdsFx.pending),
  } as const;
};
