import { dataOnly } from 'api/instance';
import { ApiResponse2 } from 'api/types';
import { WithBookkeeper, WithBookkeeperPascal } from 'api/types/request';
import {
  AssignGetParams,
  AssignRoleUsers,
  AssignUserRoles,
  AssignUserRolesUpdate,
  Role,
  RoleCreateForm,
  RolesAssignGroupBy,
  RolesState,
  RolesStateSet,
  RoleTagBulkUpdate,
  RoleTagCustomerAssignment,
  RoleTagCustomerUpdate,
  RoleTagGlobalAssignment,
  RoleTagGlobalUpdate,
  RoleUpdateForm,
  RoleUsers,
  RoleUsersUpdates,
  UsedRoleCustomer,
  UsedRoleCustomersParams,
  WithCustomerId,
  WithRoleId,
  WithTeamId,
} from 'models/roles/types';

export const apiRoles = (params: WithBookkeeper) =>
  dataOnly.get<null, ApiResponse2<readonly Role[]>>('/api2/roles', { params });

export const apiRoleCreate = (form: WithBookkeeper & RoleCreateForm) =>
  dataOnly.post<null, ApiResponse2<Role>>('/api2/roles', form);

export const apiRoleUpdate = ({ id, ...form }: WithBookkeeper & WithRoleId & RoleUpdateForm) =>
  dataOnly.patch<null, ApiResponse2<Role>>(`/api2/roles/${Number(id)}`, form);

export const apiRoleDelete = ({ id, ...data }: WithBookkeeper & WithRoleId) =>
  dataOnly.delete<null, void>(`/api2/roles/${Number(id)}`, { data });

export const apiRoleState = (params: WithBookkeeper) =>
  dataOnly.get<null, ApiResponse2<RolesState>>(`/api2/roles/switch`, { params });

export const apiRoleStateUpdate = (data: WithBookkeeper & RolesStateSet) =>
  dataOnly.post<null, ApiResponse2<RolesState>>(`/api2/roles/switch`, data);

// --------------------------

export const apiRolesTeam = ({ id, ...params }: WithBookkeeperPascal & WithCustomerId) =>
  dataOnly.get<null, ApiResponse2<{ RoleUsers: RoleUsers }>>(
    `/api2/roles/teams/${encodeURIComponent(id)}`,
    { params },
  );

/** @deprecated use v1 */
export const apiRolesTeamUpdate = ({ id, ...data }: any & WithCustomerId & WithBookkeeperPascal) =>
  dataOnly.post<null, ApiResponse2<unknown>>(`/api2/roles/teams/${encodeURIComponent(id)}`, data);

export const apiRolesTeamUpdateV1 = ({
  id,
  ...data
}: { roleUsers: RoleUsersUpdates } & WithCustomerId & WithBookkeeperPascal) =>
  dataOnly.post<null, ApiResponse2<unknown>>(
    `/api2/v1/roles/teams/${encodeURIComponent(id)}`,
    data,
  );

// --------------------------

const byUser: AssignGetParams = { group_by: RolesAssignGroupBy.user };
const byRole: AssignGetParams = { group_by: RolesAssignGroupBy.role };

export const apiRoleAssignGetByUser = (params: WithBookkeeper) =>
  dataOnly.get<null, ApiResponse2<AssignUserRoles>>(`/api2/roles/users`, {
    params: { ...params, ...byUser },
  });

export const apiRoleAssignGetByRole = (params: WithBookkeeper) =>
  dataOnly.get<null, ApiResponse2<AssignRoleUsers, true>>(`/api2/roles/users`, {
    params: { ...params, ...byRole },
  });

export const apiRoleAssignUpdateUser = ({
  _targetUserId,
  ...form
}: WithBookkeeper & AssignUserRolesUpdate) =>
  dataOnly.post<null, void>(`/api2/roles/users/${encodeURIComponent(_targetUserId)}`, form);

export const apiRoleAssignedCustomers = ({
  _forUserId,
  ...params
}: WithBookkeeper & UsedRoleCustomersParams) =>
  dataOnly.get<null, ApiResponse2<readonly UsedRoleCustomer[]>>(
    `/api2/roles/users/${_forUserId}/customers`,
    { params },
  );

// ===================================================

export const apiRoleTagsGlobalList = (params: WithBookkeeperPascal) =>
  dataOnly.get<null, ApiResponse2<readonly RoleTagGlobalAssignment[]>>(
    `/api2/tags/customer-settings/global`,
    { params },
  );

export const apiRoleTagsGlobalUpdate = (form: WithBookkeeperPascal & RoleTagGlobalUpdate) =>
  dataOnly.patch<null, ApiResponse2<RoleTagGlobalAssignment>>(
    `/api2/tags/customer-settings/global`,
    form,
  );

export const apiRoleTagsBulkUpdate = (form: WithBookkeeperPascal & RoleTagBulkUpdate) =>
  dataOnly.post<null, void>(`/api2/tags/customer-settings/bulk`, form);

export const apiRoleTagsCustomer = ({ _teamId, ...params }: WithBookkeeperPascal & WithTeamId) =>
  dataOnly.get<null, ApiResponse2<readonly RoleTagCustomerAssignment[]>>(
    `/api2/tags/customer-settings/${encodeURIComponent(_teamId)}`,
    { params },
  );

export const apiRoleTagsCustomerUpdate = ({
  _teamId,
  ...form
}: WithBookkeeperPascal & WithTeamId & RoleTagCustomerUpdate) =>
  dataOnly.patch<null, ApiResponse2<RoleTagCustomerAssignment>>(
    `/api2/tags/customer-settings/${encodeURIComponent(_teamId)}`,
    form,
  );
