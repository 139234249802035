import { CSSProperties, FC, MouseEventHandler } from 'react';
import cl from './TagItem2.module.scss';
import { CommonTag } from 'models/tags/types';
import cn from 'classnames';
import { TextButton2 } from '../../button';

// REFACT: если связано с моделями, то должно быть в /components/...?

interface Props {
  tag: CommonTag;
  className?: string;
  style?: CSSProperties;
  isHoverForDelete?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onDeleteItem?: () => void;
  disabled?: boolean;
}

export const TagItem2: FC<Props> = ({
  tag,
  className,
  style,
  isHoverForDelete = false,
  onClick,
  onDeleteItem,
  disabled,
}) => {
  return (
    <div
      className={cn(
        cl.root,
        isHoverForDelete && cl.isHoverForDelete,
        disabled && cl._disabled,
        className,
      )}
      style={{ backgroundColor: tag.color, ...style }}
      onClick={disabled ? undefined : onClick}
      title={tag.tag}
    >
      <span>{tag.tag}</span>
      {onDeleteItem && !disabled && (
        <TextButton2 icon="Cross" size="small" className={cl.btnDelete} onClick={onDeleteItem} />
      )}
    </div>
  );
};
