import { IdNumberOf, OptOutRestProps, WritablePart } from 'utils/types';
import { RoleId } from '../roles/types';
import { Task } from '../task/types';
import { TaskChecklist } from '../task-checklist/types';
import { TaskFinanceService } from '../task-finance/types';
import { UserId } from '../user/types';
import { TaskChecklistItemSimpleWithId } from 'routes/main/routes/task/components/TaskEditModal/modelCheckList';

export interface TaskChecklistSimpleRO extends Pick<TaskChecklist, 'title'> {
  items: readonly TaskChecklistItemSimpleWithId[];
}

export type TaskTemplateId = IdNumberOf<'TaskTemplate'>;

export interface TaskTemplate
  extends Pick<Task, 'files' | 'priority' | 'tags' | 'text' | 'user_id'> {
  id: TaskTemplateId;
  /**
   * Название шаблона
   */
  title: string;
  sort: number;
  /**
   * Эти значения хранятся в виде текста, не синхронизируются с изменениями в бд.
   * Поэтому нужно проверять что эти роли все еще существуют.
   *
   * Роли пользователей, которых нужно назначить. В задачу прописываются пользователи,
   * закрепленные за ролями.
   * Из ролей берутся только те пользователи, которые являются ответственными в
   * клиентской компании, к которой относится задача.
   * Если этот параметр передан вместе с `assignees`, то списки пользователей
   * складываются.
   */
  assigned_user_roles: readonly RoleId[] | null;
  /**
   * Эти значения хранятся в виде текста, не синхронизируются с изменениями в бд.
   * Поэтому нужно проверять что пользователи с этими ID все еще являются участниками бухкомпании.
   *
   * Список идентификаторов пользователей, ответственных за задачу
   */
  assignees: readonly UserId[] | null;
  /**
   * Автоматически назначить ответственных из списка бухгалтеров, состоящих в
   * активных каналах клиентской компании.
   * Если `true`, то параметр `assignees` игнорируется.
   */
  auto_assign: boolean;
  /**
   * Роли пользователей, которые будут видеть шаблоны.
   */
  template_assigned_user_roles: readonly RoleId[];
  /**
   * Список идентификаторов пользователей, которые будут видеть шаблоны.
   * Если не указан ни один пользователь или роль, то шаблон будет виден всем.
   */
  template_assignees: readonly UserId[];
  checklists: readonly TaskChecklistSimpleRO[];

  add_to_date: boolean;
  plan_minutes: number;
  services: readonly TaskTemplateService[];
}

export interface TaskTemplateCreateForm
  extends OptOutRestProps<Omit<TaskTemplate, 'user_id' | 'id'>, 'title' | 'priority' | 'text'> {
  // неудобно получается, поэтому подменяю `{}` на id прямо в отправке АПИ запроса
  // tags: TagId[];
  // files: string[];
}

export interface TaskTemplateUpdateForm extends Partial<TaskTemplateCreateForm> {}

export const enum TaskTemplatesListMode {
  /** вернуть все шаблоны бухкомпании */
  REVIEWER = 'reviewer',
  /** только шаблоны, которые соответствуют указанным в них ролям для текущего пользователя, а также шаблоны без указанных ответственных */
  USER = 'user',
}

export interface WithTaskTemplateId {
  _id: TaskTemplateId;
}

// `title` обязателен
interface TaskTemplateService extends Partial<WritablePart<TaskFinanceService>> {}

export const cmpTemplates = (a: TaskTemplate, b: TaskTemplate) => a.sort - b.sort || a.id - b.id;
