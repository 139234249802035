import {
  ElementType,
  FC,
  ImgHTMLAttributes,
  MouseEvent,
  ReactElement,
  ReactNode,
  RefCallback,
  useCallback,
  useState,
} from 'react';
import cn from 'classnames';
import { Placement } from '@popperjs/core/lib/enums';
import type { TooltipAttributes } from 'components/utils/display/Tooltip/model/types';
import { EMPTY_ARRAY } from 'constants/utils';
import { boolNot, fnVoid } from 'utils/fn';
import Icon, { IIconProps } from 'ui/Icon';
import { MenuItem } from 'ui/list/MenuItem';
import { LinkProps } from 'ui/types';
import { Divider, Popover2 } from '../../display';
import { ButtonCoreProps } from '../core';
import { Button2, TextButton2 } from '../Button';
import cl from './ButtonDropdown.module.scss';

// ------------------------------------
// ICON

type TIcon<T extends string, P extends object> = { type: T } & P;
type PIconIcon = { icon: IIconProps['type'] } & Omit<IIconProps, 'type'>;
type PIconImg = ImgHTMLAttributes<HTMLImageElement>;
type PIconFC = { fc: FC<{ className?: string }> };
type PIconNode = { node: ReactNode; className?: string };
type ItemIcon =
  | TIcon<'icon', PIconIcon>
  | TIcon<'img', PIconImg>
  | TIcon<'fc', PIconFC>
  | TIcon<'node', PIconNode>;

const RenderIcon: FC<{ icon: ItemIcon; className?: string }> = ({ icon, className }) => {
  switch (icon.type) {
    case 'icon': {
      const { type, icon: iconType, ...rest } = icon;
      return <Icon {...rest} type={icon.icon} className={cn(className, rest.className)} />;
    }

    case 'img': {
      const { type, ...rest } = icon;
      return <img {...rest} alt={rest.alt ?? ''} className={cn(className, rest.className)} />;
    }

    case 'fc': {
      const { fc: C } = icon;
      return <C className={className} />;
    }

    case 'node':
      return <span className={cn(className, icon.className)}>{icon.node}</span>;

    default:
      if (process.env.NODE_ENV === 'development') {
        return <code className={className}>{(icon as any).type}</code>;
      }
      return null;
  }
};

// ------------------------------------
// POPUP

export interface ButtonDropdownItemWrapProps {
  content: ReactNode;
  hasIcons: boolean;
  hasHint: boolean;
}

export interface ButtonDropdownItem extends LinkProps, TooltipAttributes {
  icon?: ItemIcon;
  wrap?: (p: ButtonDropdownItemWrapProps) => ReactElement;
  label?: ReactNode;
  labelClassName?: string;
  hint?: ReactNode;
  hintClassName?: string;
  disabled?: boolean;
  onClick?: () => void;
  submenu?: PopupProps;
  component?: ElementType;
  className?: string;
}

type PopupFlip = boolean | readonly Placement[];
const FLIP_DEFAULT: PopupFlip = ['auto'];
interface PopupOptProps {
  popupRootClassName?: string;
  /**
   * По умолчанию высота меню ограничивается высотой видимой области, и при
   * необходимости появляется скролл. Работает нормально (вроде) в комбинации
   * с `popupFlip` по умолчанию (`auto`).
   *
   * Эта опция позволяет отключить это поведение, как было раньше без него.
   *
   * @see https://trello.com/c/oSKSIsdX/596
   */
  popupRootFitDisable?: boolean;
  popupWrapperClassName?: string;
  popupClassName?: string;
  /** Расположение главного меню. Для дочерних см. `popupPlacementSub`. */
  popupPlacement?: Placement;
  /** Расположение для вложенных меню. Параметр `level` начинается с `1`. */
  popupPlacementSub?: Placement | ((level: number) => Placement);
  /**
   * Управление модификатором `flip` главного меню. Для дочерних см.
   * `popupFlipSub`.
   * По умолчанию `['auto']`.
   * См. UI Popper2 `flip`.
   */
  popupFlip?: PopupFlip;
  /**
   * Управление модификатором `flip` для вложенных меню. Параметр `level`
   * начинается с `1`.
   * По умолчанию `['auto']`.
   * См. UI Popper2 `flip`.
   */
  popupFlipSub?: PopupFlip | ((level: number) => PopupFlip);
  popupRaw?: boolean;
}
const mergePopupOpt = (
  parent: PopupOptProps,
  {
    popupRootClassName = parent.popupRootClassName,
    popupRootFitDisable = parent.popupRootFitDisable,
    popupClassName = parent.popupClassName,
    popupPlacement = parent.popupPlacement,
    popupPlacementSub = parent.popupPlacementSub,
    popupFlip = parent.popupFlip,
    popupFlipSub = parent.popupFlipSub,
    popupRaw = parent.popupRaw,
  }: PopupOptProps,
): PopupOptProps => ({
  popupRootClassName,
  popupRootFitDisable,
  popupClassName,
  popupPlacement,
  popupPlacementSub,
  popupFlip,
  popupFlipSub,
  popupRaw,
});

type SkipItem = boolean | null | undefined;
const isSkipItem = (i: any): i is SkipItem =>
  i === null || i === undefined || i === true || i === false;

type DividerItem = '-';
const isDividerItem = (i: any): i is DividerItem => i === '-';

type ButtonDropdownItemVisible = ButtonDropdownItem | DividerItem;
export type ButtonDropdownItemOptional = ButtonDropdownItemVisible | SkipItem;
export type ButtonDropdownItemsOptional = readonly ButtonDropdownItemOptional[];
export const hasVisibleDropdownItems = (items: ButtonDropdownItemsOptional): boolean =>
  items.some((item) => !isSkipItem(item) && !isDividerItem(item));

export const filterDisplayItems = (
  items: ButtonDropdownItemsOptional,
): ButtonDropdownItemVisible[] => {
  const result: ButtonDropdownItemVisible[] = [];
  for (const item of items) {
    if (isSkipItem(item)) continue;
    if (isDividerItem(item)) {
      // пропускам разделитель в начале
      if (!result.length) continue;
      // пропускаем разделитель после разделителя
      if (isDividerItem(result[result.length - 1])) continue;
    }
    result.push(item);
  }
  // и убираем разделитель в конце
  if (result.length && isDividerItem(result[result.length - 1])) {
    result.pop();
  }

  return result;
};

interface PopupProps extends PopupOptProps {
  /**
   * Элементы меню
   *
   * - `boolean`, `null` и `undefined` пропускаются, чтобы проще делать условные элементы.
   * - `"-"` выводит разделитель. Лишние разделители не выводятся (в начале,
   *   в конце и соседние).
   */
  items: ButtonDropdownItemsOptional;
  itemsHeader?: ReactNode;
  isCloseOnClickOutside?: boolean;
}
interface PopupInternalProps {
  level: number;
}

interface PopupOwnProps {
  onClose?: () => void;
}
const RenderPopup: FC<PopupProps & PopupInternalProps & PopupOwnProps> = ({
  items,
  itemsHeader,
  onClose,
  children,
  level,
  ...restPopup
}) => {
  const hasIcons = items.some((i) => !isSkipItem(i) && !isDividerItem(i) && i.icon !== undefined);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      // if click handled by an item
      if (e.isDefaultPrevented()) {
        onClose?.();
      }
    },
    [onClose],
  );

  return (
    <div
      className={cn(cl.popup, hasIcons && cl._hasIcons, restPopup.popupClassName)}
      onClick={handleClick}
    >
      {itemsHeader && <div className={cl.header}>{itemsHeader}</div>}
      {filterDisplayItems(items).map((item, i) => {
        if (isDividerItem(item)) {
          return <Divider key={i} className={cl.divider} />;
        }

        const {
          icon,
          wrap,
          label,
          labelClassName,
          hint,
          hintClassName,
          href,
          onClick,
          submenu,
          className,
          component,
          ...rest
        } = item;

        const content = (
          <>
            {icon && <RenderIcon icon={icon} className={cl.icon} />}
            <div className={cn(cl.label, labelClassName)}>{label}</div>
            {hint && <div className={cn(cl.hint, hintClassName)}>{hint}</div>}
          </>
        );
        const children = wrap
          ? wrap({
              content,
              hasIcons,
              hasHint: Boolean(hint),
            })
          : content;

        if (submenu) {
          const { items, itemsHeader, ...restChild } = submenu;
          if (process.env.NODE_ENV === 'development') {
            const bad = Object.entries({ href, onClick }).filter(([, v]) => v !== undefined);
            if (bad.length) {
              console.warn(
                'An item with `submenu` also have props which are unused:',
                Object.fromEntries(bad),
                { submenu },
              );
            }
          }
          return (
            <RenderDropdown
              {...mergePopupOpt(restPopup, restChild)}
              key={i}
              items={items}
              itemsHeader={itemsHeader}
              level={level + 1}
              trigger={({ ref, open, onToggle }) => (
                <div ref={ref}>
                  <MenuItem
                    {...rest}
                    key={i}
                    className={cn(cl.item, cl._hasChildren, open && cl._childrenOpen, className)}
                    component="button"
                    onClick={onToggle}
                  >
                    {children}
                    <Icon type="Down" className={cl.arrow} />
                  </MenuItem>
                </div>
              )}
            />
          );
        }

        return (
          <MenuItem
            {...rest}
            key={i}
            className={cn(cl.item, className)}
            href={href}
            component={component ?? (onClick && !href ? 'button' : undefined)}
            onClick={onClick}
            clickPreventDefault={!href}
          >
            {children}
          </MenuItem>
        );
      })}
    </div>
  );
};

// ------------------------------------
// DROPDOWN

interface DropdownOwnProps {
  trigger: (props: {
    ref: RefCallback<HTMLElement>;
    open: boolean;
    onClose: () => void;
    onToggle: () => void;
  }) => ReactElement<any, string>;
}
const RenderDropdown: FC<PopupProps & PopupInternalProps & DropdownOwnProps> = ({
  trigger,
  children,
  isCloseOnClickOutside = true,
  ...rest
}) => {
  const { popupRaw, popupRootClassName, popupRootFitDisable, level, popupWrapperClassName } = rest;

  const [tRef, setTRef] = useState<HTMLElement | null>(null);
  const [isOpenPopover, setOpenPopover] = useState(false);

  const handleClose = useCallback(() => setOpenPopover(false), []);
  const handleToggle = useCallback(() => setOpenPopover(boolNot), []);

  return (
    <>
      {trigger({
        ref: setTRef,
        open: isOpenPopover,
        onClose: handleClose,
        onToggle: handleToggle,
      })}
      <Popover2
        isShow={isOpenPopover}
        onClickOutside={isCloseOnClickOutside ? handleClose : fnVoid}
        anchorEl={tRef}
        // anchorEl={isOpenPopover ? tRef : undefined}
        placement={
          level
            ? typeof rest.popupPlacementSub === 'function'
              ? rest.popupPlacementSub(level)
              : rest.popupPlacementSub ?? 'right-start'
            : rest.popupPlacement ?? 'bottom-start'
        }
        flip={
          level
            ? typeof rest.popupFlipSub === 'function'
              ? rest.popupFlipSub(level)
              : rest.popupFlipSub ?? FLIP_DEFAULT
            : rest.popupFlip ?? FLIP_DEFAULT
        }
        // Именно так, иначе во вложенный нельзя кликнуть по элементам
        withPortal={!level}
        rawPopup={popupRaw}
        // ставлю false для обратной совместимости с прошлой реализация,
        // иначе, например, эта штука, будучи внутри диалога, уже неправильно
        // себя ведёт:
        // => Аналитика тарифов => Диалог деталей рассчета
        withBackdrop={false}
        className={cn(popupRootClassName, popupRootFitDisable || cl.popupRootFitViewport)}
        wrapperClassName={popupWrapperClassName}
      >
        <RenderPopup {...rest} onClose={handleClose} />
      </Popover2>
    </>
  );
};

// ------------------------------------
// TRIGGER

type TriggerRenderProps = Pick<
  ButtonCoreProps,
  'size' | 'uiColor' | 'icon' | 'iconPosition' | 'className' | 'style' | 'onClick' | 'isLoading'
>;
export interface ButtonDropdownButtonProps extends TriggerRenderProps {
  'data-tip'?: string;
}

// ------------------------------------
// ROOT

export interface ButtonDropdownProps extends Partial<PopupProps> {
  className?: string;
  buttonType?: 'default' | 'text' | FC<ButtonDropdownButtonProps>;
  buttonSize?: TriggerRenderProps['size'];
  buttonUiColor?: TriggerRenderProps['uiColor'];
  buttonIcon?: TriggerRenderProps['icon'] | null;
  buttonIconPosition?: TriggerRenderProps['iconPosition'] | null;
  buttonIconRotate?: boolean;
  buttonIsLoading?: TriggerRenderProps['isLoading'];
  buttonTip?: string;
  buttonClassName?: TriggerRenderProps['className'];
  buttonStyle?: TriggerRenderProps['style'];
  disabled?: boolean;
}

export const ButtonDropdown: FC<ButtonDropdownProps> = ({
  className,
  buttonType,
  buttonSize,
  buttonUiColor,
  buttonIcon = 'Down',
  buttonIconPosition,
  buttonIconRotate = true,
  buttonIsLoading,
  buttonTip,
  buttonClassName,
  buttonStyle,
  disabled,
  children,
  ...rest
}) => (
  <RenderDropdown
    {...rest}
    items={rest.items ?? EMPTY_ARRAY}
    level={0}
    trigger={({ ref, open, onToggle }) => (
      <span
        ref={ref}
        className={cn(cl.trigger, open && cl._open, buttonIconRotate && cl._rotateIcon, className)}
      >
        {((
          B = typeof buttonType === 'function'
            ? buttonType
            : buttonType === 'text'
              ? TextButton2
              : Button2,
        ) => (
          <B
            type="button"
            size={buttonSize}
            uiColor={buttonUiColor}
            icon={buttonIcon ?? undefined}
            iconPosition={buttonIconPosition ?? undefined}
            isLoading={buttonIsLoading}
            className={buttonClassName}
            style={buttonStyle}
            onClick={onToggle}
            disabled={disabled}
            // возможно, надо показывать только при !open
            data-tip={buttonTip}
          >
            {children}
          </B>
        ))()}
      </span>
    )}
  />
);
